const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/ShareballApp",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com/ShareballApp",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.instagram.com/shareball.app/",
    text: "instagram",
  },
  {
    id: 4,
    href: "https://www.tiktok.com/@shareball.app",
    text: "tiktok",
  },
  // {
  //   id: 5,
  //   href: "https://www.linkedin.com/company/shareball/",
  //   text: "linkedin",
  // },
];

export { socialIcons };
