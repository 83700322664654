import Image from "next/image";
import Link from "next/link";
import ScrollToSubscribeContext from "../ScrollToSubscribeContext";
import Logo from "./../../public/images/shareball_logo.png";
import { useEffect, useContext } from "react";
import { useTheme } from "next-themes";
import { socialIcons } from "../../data/footer_data";

export default function Header01() {
  const [, fixedHeaderRef] = useContext(ScrollToSubscribeContext);
  const { setTheme } = useTheme();

  // window resize
  useEffect(() => {
    setTheme("light");
  });

  return (
    <>
      {/* main desktop menu start*/}
      <header
        ref={fixedHeaderRef}
        className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors"
      >
        <div className="container py-2">
          <div className="flex flex-col items-center justify-between sm:flex-row sm:space-y-0">
            <Link className="shrink-0" href="/">
              <a>
                <div className="dark:hidden">
                  <Image
                    src={Logo}
                    height={40}
                    width={160}
                    alt="Shareball"
                    className="max-h-7 h-auto "
                  />
                </div>
              </a>
            </Link>
            {/* <!-- Socials --> */}
            <div className="flex items-center space-x-5">
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link href={href} key={id}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </a>
                  </Link>
                );
              })}
              <Link href="mailto:info@shareball.app" key={5}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group cursor-pointer text-sm text-jacarta-400 hover:text-accent"
                >
                  Susisiekti
                </a>
              </Link>
            </div>
          </div>
          {/* End  logo */}
        </div>
      </header>
      {/* main desktop menu end */}
    </>
  );
}
