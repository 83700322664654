import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta name="apple-itunes-app" content="app-id=6473081270" />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Mėgautis sportu su puikia komanda dar nebuvo taip paprasta!",
  keyword: "sportas, krepšinis, futbolas, beisbolas, tenisas",
  desc: "Trūksta žaidėjų komandai? Esi vienas ir nori prisijungti prie bendraminčių? Nuo šiol organizuok ir junkis į žaidimą pagal sporto šaką, miestą ir lygį patogiai su Shareball.",
};

export default Meta;
