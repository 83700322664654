import Link from "next/link";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="sm:items-start flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            {/* <!-- Logo --> */}
            <Link href="/">
              <a className="mb-6 inline-block">
                <img
                  src="/images/shareball_logo.png"
                  className="max-h-7 dark:hidden"
                  alt="Shareball"
                />
              </a>
            </Link>

            <div>
              <Link href="/terms-of-service">
                <a className="mb-6 inline-block">Paslaugų teikimo sąlygos</a>
              </Link>
              <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>
              <Link href="/privacy-policy">
                <a className="mb-6 inline-block">Privatumo politika</a>
              </Link>
            </div>

            {/* <!-- All rights reserved --> */}
            <div className="flex space-x-5">
              © {new Date().getFullYear()} Shareball. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
