import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import { GoogleAnalytics } from "nextjs-google-analytics";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useEffect, useRef } from "react";
import { hotjar } from "react-hotjar";

const HOTJAR_HJID = 3440495;
const HOTJAR_HJSV = 6;

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
  }, []);

  const scrollRef = useRef({
    scrollPos: 0,
  });
  return (
    <>
      <GoogleAnalytics trackPageViews />
      <Meta title="Home 1" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
